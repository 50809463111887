const isDevelopment =
  process.env.NODE_ENV === 'development' ||
  process.env.VERCEL_ENV === 'preview';

const PROD_LOCALES = {
  DE_DE: 'de-DE',
  EN_AU: 'en-AU',
  EN_CA: 'en-CA',
  EN_GB: 'en-GB',
  EN_IE: 'en-IE',
  EN_SG: 'en-SG',
  EN_US: 'en-US',
  ES_ES: 'es-ES',
  FR_CA: 'fr-CA',
  FR_FR: 'fr-FR',
  IT_IT: 'it-IT',
  NL_NL: 'nl-NL',
  PT_PT: 'pt-PT',
};

const BETA_LOCALES = { EN_SG: 'en-SG' };

const MINI_SITE_ONLY_LOCALES = [
  'de-DE',
  'en-SG',
  'es-ES',
  'it-IT',
  'nl-NL',
  'pt-PT',
];

const LOCALES = {
  ...PROD_LOCALES,
  ...isDevelopment ? BETA_LOCALES : {},
};

const LOCALES_LIST = Object.values(LOCALES);

const BLOG_LOCALES = [
  LOCALES.EN_US,
  LOCALES.EN_AU,
  LOCALES.NL_NL,
  LOCALES.FR_FR,
  LOCALES.DE_DE,
  LOCALES.ES_ES,
];

module.exports.BLOG_LOCALES = BLOG_LOCALES;
module.exports.PROD_LOCALES = PROD_LOCALES;
module.exports.BETA_LOCALES = BETA_LOCALES;
module.exports.LOCALES = LOCALES;
module.exports.LOCALES_LIST = LOCALES_LIST;
module.exports.MINI_SITE_ONLY_LOCALES = MINI_SITE_ONLY_LOCALES;
