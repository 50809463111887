import {
  differenceInDays, format, parse, startOfToday
} from 'date-fns';
import path from 'path';

import {
  copyToPublicDir,
  readFileFromBuildCache,
  readFileWithLog,
  readOrFetchFromPublicWithLog,
  writeFileToBuildCache,
  writeFileToPublicWithLog,
  writeFileWithLog
} from './fs-helpers';
import { logWithPrefix } from './log-helpers';

// Remove leading and trailing slashes
const removeLeadingTrailingSlashes = (url) => {
  return url.replaceAll(/^\/|\/$/g, '');
};

export const implementCaching = async ({
  fileName,
  fn,
  hardRefresh = false,
  logPrefix = 'cachingHelpers',
  writeToPublic = false,
} = {}) => {
  const filePath = `./cache/${fileName}`;

  let cachedJson = readFileWithLog({
    defaultValue: null,
    filePath,
    logPrefix,
  });

  if (!cachedJson || hardRefresh) {
    cachedJson = await fn();

    writeFileWithLog({
      content: JSON.stringify(cachedJson),
      filePath,
      logPrefix,
    });
  }

  if (writeToPublic) {
    writeFileToPublicWithLog({
      content: JSON.stringify(cachedJson),
      filePath: fileName,
      logPrefix,
    });
  }

  return cachedJson;
};

// Returns all the alternative pages
export const getAlternativePages = async ({
  contentType,
  logPrefix = 'cachingHelpers',
  url,
}) => {
  if (!url) {
    return [];
  }

  const urlObject = new URL(url);
  const searchSlug = urlObject.pathname;
  const cleanedSlug = removeLeadingTrailingSlashes(searchSlug);

  const json = await readOrFetchFromPublicWithLog({
    defaultValue: [],
    filePath: 'all-pages.json',
    logPrefix,
    noSuccessLog: true,
  });

  const findLocale = () => {
    return (json ?? []).find((groupedPages) => {
      return groupedPages.find(({ path, postType }) => {
        return postType === contentType && removeLeadingTrailingSlashes(path) === cleanedSlug;
      });
    });
  };

  const foundPages = findLocale() ?? [];

  return foundPages.map(({ locale, path }) => ({
    locale,
    path,
  }));
};

export const getProductionCssFiles = async () => {
  return await readOrFetchFromPublicWithLog({
    defaultValue: {
      rgbPath: '',
      savaPath: '',
    },
    filePath: 'production-css.json',
    logPrefix: '_helpers',
    noSuccessLog: true,
  });
};

// Save to filesystem
export const storeToCache = ({
  dirName,
  disablePublicWrite = false,
  logPrefix,
  onWriteFile,
}) => {
  if (process.env.VERCEL && process.env.CI !== '1') {
    // Cannot write to filesystem in non-CI environment
    return;
  }

  // Write config file
  writeFileToBuildCache({
    content: JSON.stringify({ lastFetchedAt: format(startOfToday(), 'yyyy-MM-dd') }),
    filePath: path.join(dirName, '_config.json'),
    logPrefix,
  });

  // Invoke callback
  onWriteFile?.();

  if (disablePublicWrite) {
    return;
  }

  copyToPublicDir({ filePath: dirName });
};

export const isCacheDataStale = ({
  daysToCache = null,
  dirName,
  logPrefix,
}) => {
  const content = readFileFromBuildCache({
    defaultValue: null,
    filePath: path.join(dirName, '_config.json'),
    logPrefix,
  });

  if (!content) {
    logWithPrefix({
      message: 'No cache found',
      prefix: logPrefix,
    });

    return true;
  }

  if (daysToCache === null) {
    return false;
  }

  return differenceInDays(
    startOfToday(),
    parse(content.lastFetchedAt, 'yyyy-MM-dd', new Date())
  ) >= 1;
};
