import { FormType } from '.';

declare global {
  interface Window {
    dataLayer?:
    { push: (event: Record<string, unknown>) => void; };
    gtag?: (
      command: 'get',
      targetId: string,
      fieldName: string,
      callback: (clientId: string) => void
    ) => void;
  }
}

export enum GAEventName {
  ButtonClick = 'button_click',
  FormError = 'form_error',
  FormStarted = 'form_started'
}

type ClickEventPayload = {
  click_url: string;
  event: GAEventName.ButtonClick;
};

type FormEventPayload = {
  event: GAEventName.FormError | GAEventName.FormStarted;
  form_type: FormType;
};

type GAEventPayload = ClickEventPayload | FormEventPayload;

export const sendGAEvent = (payload: GAEventPayload) => {
  if (typeof window === 'undefined' || !window.dataLayer) {
    return;
  }

  window.dataLayer.push({ ...payload });
};

export function getClientId(cb: (clientId: string) => void) {
  const measurementId = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;

  if (!measurementId) {
    console.error('Measurement ID is not defined');

    return null;
  }

  if (typeof window.gtag !== 'function') {
    console.warn('gtag is not defined');

    return null;
  }

  // Assign to a local variable
  const gtag = window.gtag;

  gtag('get', measurementId, 'client_id', cb);
}
