// Only need to add this list if we have custom logic in the codebase
export enum FormField {
  CompanyName = 'Company',
  Country = 'Country',
  CountryDialingCode = 'Country_Dialing_Code__c',
  Email = 'Email',
  FirstName = 'FirstName',
  FullName = 'FullName',
  HasRunPayroll = 'user_previous_payroll',
  LastName = 'LastName',
  NumberOfPeople = 'user_employees',
  PhoneNumber = 'Phone',
  State = 'State'
}

export enum FormType {
  ContactUs = 'Contact Us',
  Content = 'Content',
  DemoDropOff = 'Demo Drop Off',
  DemoRequest = 'Demo Request',
  GiftOptIn = 'Gift Opt-In',
  Other = 'Other',
  PEODemoRequest = 'PEO Demo Request',
  ProductTour = 'Product Tour',
  QuoteRequest = 'Quote Request',
  SelfGuidedTour = 'Self-Guided Tour',
  VideoTour = 'Video Tour',
  Webinars = 'Webinars'
}

export type OpenpriseFieldKey =
  | 'chiliPiperFlowData'
  | 'cid'
  | 'Email'
  | 'FirstName'
  | 'Form_Type__c'
  | 'NumberOfEmployees'
  | 'ReferrerSource'
  | 'rtlid'
  | keyof Record<FormField, string>;

