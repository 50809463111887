import {
  useCallback, useEffect, useRef, useState
} from 'react';

import type {
  AirgapAPI, PreInitTranscendAPI, TrackingConsent, TranscendAPI
} from '@transcend-io/airgap.js-types';

type AirgapReadyCallback = (api: AirgapAPI) => void;

/** A stub for `window.airgap` to be used before airgap is actually loaded */
type PreInitAirgapAPI = Required<Pick<AirgapAPI, 'ready' | 'readyQueue'>>;

/**
 * Add `window.airgap` and `window.transcend` to the global namespace
 */
declare global {
  interface Window {
    airgap?: AirgapAPI | PreInitAirgapAPI;
    transcend?: PreInitTranscendAPI | TranscendAPI;
  }
}

interface ConsentStatus { purposes: TrackingConsent; }

export interface ConsentAPI {
  airgap?: AirgapAPI;
  hasAdvertisingConsent?: boolean;
  hasAnalyticsConsent?: boolean;
  hasFunctionalConsent?: boolean;
  subscribeToConsentChanges?: (callback: (consent: ConsentStatus) => void) => () => void;
  transcend?: TranscendAPI;
}

export const useConsentManager = (): ConsentAPI => {
  const isLoadedRef = useRef(false);
  const [consentStatus, setConsentStatus] = useState<ConsentStatus | null>(null);
  const subscribers = useRef<Set<(consent: ConsentStatus) => void>>(new Set());

  useEffect(() => {
    if (!window.airgap?.ready) {
      const preInitAirgap: PreInitAirgapAPI = {
        ready(callback: AirgapReadyCallback) {
          this.readyQueue.push(callback);
        },
        readyQueue: [],
        ...window.airgap,
      };
      window.airgap = preInitAirgap;
    }

    const updateConsent = (event: Event) => {
      const { consent } = (event as CustomEvent).detail as { consent: ConsentStatus };

      setConsentStatus(consent);
      for (const callback of subscribers.current) {
        callback(consent);
      }
    };

    const airgap = window.airgap as AirgapAPI;

    airgap.ready((airgap) => {
      isLoadedRef.current = true;
      airgap.addEventListener('consent-change', updateConsent);
      airgap.addEventListener('consent-resolution', updateConsent);
    });

    return () => {
      if (isLoadedRef.current) {
        airgap.removeEventListener('consent-change', updateConsent);
        airgap.removeEventListener('consent-resolution', updateConsent);
      }
    };
  }, []);

  const hasFunctionalConsent = !!consentStatus?.purposes?.Functional;
  const hasAdvertisingConsent = !!consentStatus?.purposes?.Advertising;
  const hasAnalyticsConsent = !!consentStatus?.purposes?.Analytics;

  const subscribeToConsentChanges = useCallback((callback: (consent: ConsentStatus) => void) => {
    subscribers.current.add(callback);

    return () => {
      subscribers.current.delete(callback);
    };
  }, []);

  return {
    hasAdvertisingConsent,
    hasAnalyticsConsent,
    hasFunctionalConsent,
    subscribeToConsentChanges,
  };
};
