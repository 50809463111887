import { FX_RATE_DIR } from '../constants/cache';
import { BLOG_POST_WP_TYPE } from '../contentTypes/blogPost';
import { COUNTRY_GUIDE_WP_TYPE } from '../contentTypes/countryGuide';
import { CUSTOMER_SPOTLIGHT_WP_TYPE } from '../contentTypes/customerSpotlight';
import { GLOSSARY_TERM_WP_TYPE } from '../contentTypes/glossaryTerm';
import { PAGE_WP_TYPE } from '../contentTypes/page';
import { RECIPE_WP_TYPE } from '../contentTypes/recipe';
import { getAlternativePages, getProductionCssFiles } from '../helpers/caching-helpers';
import { readOrFetchFromPublicWithLog } from '../helpers/fs-helpers';
import { LOCALES } from '../locales';
import { checkIsDevelopment, fetchAPI } from './_fetchAPI';
import { resolveReusables } from './_reusables';
import {
  filterEmptyBlockNames,
  localize,
  localizeMeta,
  replacePartialValues
} from './_wordpress';
import { getGlobals } from './globals';

const getWpStatus = ({ isDevelopment }) => {
  return isDevelopment ? ['DRAFT', 'PUBLISH'] : ['PUBLISH'];
};

/**
 * WARNING... THIS IS A JS COPY OF @rippling/utils/networkUtils attemptWithRetries
 * These should be merged at some point. DO NOT maintain this one.
 */
export const attemptWithRetries = async (
  fn,
  retryLimit = 5,
  retriesLeft = retryLimit
) => {
  try {
    return await fn();
  } catch (error) {
    if (retriesLeft > 0) {
      return await attemptWithRetries(fn, retryLimit, retriesLeft - 1);
    } else {
      console.error(
        `Failed to run attempted function after ${retryLimit} attempts.\n`,
        error
      );
      throw error;
    }
  }
};

export const isValidNumber = (data) => {
  return !Number.isNaN(Number(data));
};

const postTypes = new Set([
  'career',
  CUSTOMER_SPOTLIGHT_WP_TYPE,
  'event',
  'meetup',
  PAGE_WP_TYPE,
  'partial',
  BLOG_POST_WP_TYPE,
  RECIPE_WP_TYPE,
  'resourceTemp',
  COUNTRY_GUIDE_WP_TYPE,
  'webinar',
  GLOSSARY_TERM_WP_TYPE,
]);

export const getPostPublishedLocales = (post) => {
  return (
    post?.meta?.global?._localesPublished ||
    post?.meta?.global?._locales || [LOCALES.EN_US]
  );
};

const getPostTranslatedLocales = (post) => {
  return post?.meta?.global?._locales || [];
};

export async function fetchAllRecords(
  query,
  {
    forceProd, maxLimit, variables,
  } = {}
) {
  const PAGE_SIZE = 50;
  let allData = [];
  let cursor = null;
  let hasNextPage = true;
  let alreadyFetched = 0;

  while (hasNextPage) {
    try {
      const data = await fetchApiWithRetries(
        query,
        {
          variables: {
            ...variables,
            after: cursor,
            first: PAGE_SIZE,
          },
        },
        forceProd
      );
      const pageInfoKey = Object.keys(data).find((key) => data[key].pageInfo);

      const currentPageData = data[pageInfoKey];

      if (!currentPageData) {
        throw new Error('Unexpected response structure');
      }

      allData = allData.concat(currentPageData.nodes);
      cursor = currentPageData.pageInfo.endCursor;
      hasNextPage = currentPageData.pageInfo.hasNextPage;

      if (maxLimit && hasNextPage) {
        alreadyFetched += currentPageData.nodes.length;
        hasNextPage = alreadyFetched < maxLimit;
      }
    } catch (error) {
      console.error('Pagination Error:', error.message);
      throw new Error(`Pagination Error: ${error.message}`);
    }
  }

  return allData;
}

export const fetchApiWithRetries = async (
  query,
  { variables } = {},
  forceProd
) => {
  const fetchHandler = async () =>
    await fetchAPI(query, { variables }, forceProd);

  const attemptedResult = await attemptWithRetries(fetchHandler);

  if (!attemptedResult) {
    throw new Error(`Failed to fetch and parse. Error output should be above this message. ${JSON.stringify(
      {
        query,
        variables,
      },
      undefined,
      4
    )}`);
  }

  return attemptedResult;
};

export const parseJSONS = (post) => {
  const blocks = post.blocks ? JSON.parse(post.blocks) : null;
  const meta = post.meta ? JSON.parse(post.meta) : null;
  const data = post.data ? JSON.parse(post.data) : null;
  const partials = post.partials ? JSON.parse(post.partials) : null;
  const wp = post.wp ? JSON.parse(post.wp) : null;

  return {
    ...post,
    blocks,
    data,
    meta,
    partials,
    wp,
  };
};

export async function getPartial(slug) {
  const data = await fetchApiWithRetries(`
    query Partial {
      partial(id: "${slug}", idType: SLUG) {
        meta(locale: EN_US)
      }
    }
  `);

  return data;
}

export async function getAuthorDataPartial(slug) {
  const rawPartial = await getPartial(slug);
  const parsedMeta = JSON.parse(rawPartial.partial.meta);

  return parsedMeta.authorData;
}

export async function getBlogHomePageConfig() {
  const data = await fetchApiWithRetries(`
    query Partial {
      partial(id: "blog-home-index", idType: SLUG) {
        meta
      }
    }
  `);

  return JSON.parse(data.partial.meta);
}

const pagesToExclude = [
  'select-quote-unity',
  'select-quote-broker-unity',
  'select-quote-accountant-unity',
  'select-quote/accountant',
  'select-quote/broker',
  'select-quote',
];

export const getWpLocaleEnum = (locale) => {
  const [lang, country] = locale.split('-');

  return `${lang.toUpperCase()}_${country.toUpperCase()}`;
};

const getPostDatabaseId = (type, slug) => {
  // pages without slugs will use database ids. these are only allowed in non-prod environments
  if (checkIsDevelopment() && isValidNumber(slug)) {
    return 'DATABASE_ID';
  }

  // "page" Wp types have different "idTypes" for slugs
  if (type === PAGE_WP_TYPE) {
    return 'URI';
  }

  return 'SLUG';
};

const isMoreThan30MinutesFromDeploy = () => {
  const thirtyMinutes = 1_800_000;
  const timeSinceLastDeploy =
    Date.now() - Number.parseInt(process.env.DEPLOY_DATE, 10);

  return timeSinceLastDeploy > thirtyMinutes;
};

export const shouldFetchPost = () => {
  return (
    process.env.VERCEL_ENV !== 'production' ||
    process.env.VERCEL_ENV === 'production' && isMoreThan30MinutesFromDeploy()
  );
};

const normalizeDataFromPrebuiltCache = ({ locale, wpData: post }) => {
  try {
    post.blocks = replacePartialValues(localize(post.blocks, locale));
    post.blocks = filterEmptyBlockNames(post.blocks);
    post.meta = localizeMeta(post.meta, locale);

    return post;
  } catch (error) {
    console.log('e:', error);

    // console.log(`Failed to pull post ${slug} from pre-build cache`)
  }

  return null;
};

const fetchPost = async ({
  idType, locale, slug, type,
}) => {
  const fields = `
    uri
    modified
    blocks(locale: $locale)
    data(locale: $locale)
    meta(locale: $locale)
    partials(locale: $locale)
    wp
  `;
  let response;

  if (type === BLOG_POST_WP_TYPE) {
    const res = await fetchApiWithRetries(
      `
      query Post($locale: LocaleEnum) {
        posts(where: {slugContains: "${slug}", tagSlugIn: "${locale}"}) {
          nodes {
            modified
            blocks(locale: $locale)
            meta(locale: $locale)
            partials(locale: $locale)
            wp
          }
        }
      }
    `,
      { variables: { locale: getWpLocaleEnum(locale) } }
    );

    response = { post: res?.posts?.nodes?.[0] };
  } else {
    response = await fetchApiWithRetries(
      `
      query Post($locale: LocaleEnum) {
        post: ${type}(id: "${slug}", idType: ${idType}) {
          ${fields}
        }
      }
    `,
      { variables: { locale: getWpLocaleEnum(locale) } }
    );
  }

  if (!response.post) {
    return null;
  }

  if (type === PAGE_WP_TYPE) {
    // WPGraphQL bug -- https://github.com/wp-graphql/wp-graphql/issues/3042
    const uri = response.post.uri ?? '';
    let pathname = uri;

    // local WPGraphQL doesn't return FQDN
    if (!uri.startsWith('/')) {
      const url = new URL(uri);
      pathname = url.pathname;
    }

    // Remove leading and trailing slashes
    pathname = pathname.replaceAll(/^\/|\/$/g, '');
    const normalizedSlug = slug.replaceAll(/^\/|\/$/g, '');

    if (pathname !== normalizedSlug) {
      return null;
    }
  }

  try {
    const parsePost = parseJSONS(response.post);
    parsePost.meta = localizeMeta(parsePost.meta, locale);

    return parsePost;
  } catch (error) {
    console.error('An error occurred:', error.message);

    return parseJSONS(response.post);
  }
};

const retrievePost = async ({
  idType, locale, slug, type, wpData,
}) => {
  let post;

  if (wpData) {
    post = normalizeDataFromPrebuiltCache({
      locale,
      wpData,
    });
  }

  if (!post) {
    post = await fetchPost({
      idType,
      locale,
      slug,
      type,
    });
  }

  return post;
};

const shouldRefetchPostInAvailableLocale = ({
  publishedLocales,
  requestedLocale,
}) => {
  return !publishedLocales.includes(requestedLocale);
};

const shouldRefetchWithEnUsContent = ({ fetchedLocale, translatedLocales }) => {
  // if en-us was requested, theres nothing to do
  if (fetchedLocale === LOCALES.EN_US) {
    return false;
  }

  // if requested locale is not in translated locales... then we want the en-US content only
  return !translatedLocales.includes(fetchedLocale);
};

const getPostFallbackLocale = (publishedLocales) => {
  // we want to try and show en-US content for any missing locales.
  // in the case this is a foreign market page only (like en-GB), we'll show whatever is first available.
  // we may want to revisit this in the future - showing Country content in any language vs en-US first.
  return publishedLocales.includes(LOCALES.EN_US)
    ? LOCALES.EN_US
    : publishedLocales[0];
};

const wpPostResponseIsValid = ({ post, type }) => {
  // if reponse was falsy (empty)
  if (!post) {
    return false;
  }

  // if reponse is non empty, but has no blocks
  if (
    type === PAGE_WP_TYPE &&
    Array.isArray(post.blocks) &&
    post.blocks === 0
  ) {
    return false;
  }

  return true;
};

export const getPost = async ({
  locale,
  overrideContentLocale = null,
  pageLocaleDefaulted = false,
  slug,
  type,
  wpData,
}) => {
  if (pagesToExclude.includes(slug)) {
    return null;
  }

  const idType = getPostDatabaseId(type, slug);

  // the override content locale works silently...
  const localeToFetch = overrideContentLocale || locale;

  const post = await retrievePost({
    idType,
    locale: localeToFetch,
    slug,
    type,
    wpData,
  });

  if (!wpPostResponseIsValid({
    post,
    type,
  })) {
    return null;
  }

  if (type === BLOG_POST_WP_TYPE) {
    const res = await fetchApiWithRetries(
      `
      query Post($locale: LocaleEnum) {
        posts(where: {slugContains: "${slug}", tagSlugIn: "${locale}"}) {
          nodes {
            data(locale: $locale)
          }
        }
      }
    `,
      { variables: { locale: getWpLocaleEnum(locale) } }
    );

    const parsedPosts = res.posts.nodes.map((post) => parseJSONS(post));
    const sameCategoryPosts = parsedPosts[0]?.data.sameCategoryPosts ?? [];

    post.data = { sameCategoryPosts };
  }

  // we add pageLocale here so we can track what locale content we're rendering
  // this should remain the requested page locale (locale) to that override content is seen as such
  // and we dont accidentally get the i18n not allowed banner.
  post.pageLocale = locale;

  // we want to track when this happens...
  // this supports the "locale" not available experience
  post.pageLocaleDefaulted = pageLocaleDefaulted;
  post.overrideContentLocale = overrideContentLocale;

  /**
   * STEP 1:
   * Lets decide if the requested locale is allowed for this page first...
   */
  const publishedLocales = getPostPublishedLocales(post);
  const shouldReplacePostLocale = shouldRefetchPostInAvailableLocale({
    publishedLocales,
    requestedLocale: locale,
  });

  // THIS SHOULD BE MOVED TO THE API LAYER...
  // if its not a published locale, lets replace it with the first available one...
  if (shouldReplacePostLocale && type !== BLOG_POST_WP_TYPE) {
    const replacementLocale = getPostFallbackLocale(publishedLocales);

    return await getPost({
      locale: replacementLocale,
      pageLocaleDefaulted: true,
      slug,
      type,
    });
  }

  /**
   * STEP 2:
   * If locale is "published" (allowed) for this page, lets see if its translated too...
   */
  const translatedLocales = getPostTranslatedLocales(post);
  const postIsEnUsContentOnly = shouldRefetchWithEnUsContent({
    fetchedLocale: localeToFetch,
    translatedLocales,
  });

  // if the locale is not a "translated" locale, we'll want to refetch with only en-US content.
  if (postIsEnUsContentOnly && type !== BLOG_POST_WP_TYPE) {
    return await getPost({
      locale,

      // the override content locale works silently...
      overrideContentLocale: LOCALES.EN_US,
      pageLocaleDefaulted,
      slug,
      type,
    });
  }

  return post;
};

export const getData = async ({
  locale,
  previewData,
  query,
  slug,
  type,
  ...options
}) => {
  return await query({
    ...options,
    locale,
    previewData,
    slug,
    type,
  });
};

export const getWPData = async ({
  locale,
  previewData,
  slug,
  type,
  ...options
}) => {
  const query = postTypes.has(type) ? getPost : getPosts;

  return await getData({
    ...options,
    locale,
    previewData,
    query,
    slug,
    type,
  });
};

const sortEvents = (events) => {
  const sortedEvents = events.sort((a, b) => {
    const dateA = a.meta.events._eventDate;
    const dateB = b.meta.events._eventDate;
    if (!dateA || !dateB) {
      return 0;
    }

    return Date.parse(dateA) - Date.parse(dateB);
  });

  return sortedEvents;
};

const filterAndCategorizeWebinars = (webinars, isCustomerWebinars) => {
  const categorizedWebinars = {
    'on-demand': [],
    upcoming: [],
  };

  webinars = webinars.filter((webinar) => {
    if (
      webinar.meta.webinars._indices &&
      webinar.meta.webinars._indices.includes(isCustomerWebinars ? 'customers' : 'default')
    ) {
      return true;
    }

    return false;
  });

  for (const webinar of webinars) {
    if (webinar.meta.webinars._type === 'on-demand') {
      categorizedWebinars['on-demand'].push(webinar);
    } else {
      categorizedWebinars.upcoming.push(webinar);
    }
  }

  const months = {
    April: 3,
    August: 7,
    December: 11,
    February: 1,
    January: 0,
    July: 6,
    June: 5,
    March: 2,
    May: 4,
    November: 10,
    October: 9,
    September: 8,
  };

  const filteredUpcomingWebinars = categorizedWebinars.upcoming
    .filter((webinar) => {
      if (!webinar.meta.webinars.webinarDate) {
        return false;
      }
      let [
        month,
        dayWithComma,
        year,
      ] = webinar.meta.webinars.webinarDate.split(' ');
      month = months[month];

      if (month) {
        const day = dayWithComma.replace(',', '');

        const newDate = new Date(
          Number.parseInt(year, 10),
          Number.parseInt(month, 10),
          Number.parseInt(day, 10)
        );
        const currentDate = new Date();

        // Future dates logic
        if (newDate.getTime() > currentDate.getTime()) {
          return true;
        }

        // Todays date logic
        if (
          newDate.getMonth() === currentDate.getMonth() &&
          newDate.getDate() === currentDate.getDate() &&
          newDate.getFullYear() === currentDate.getFullYear() &&
          new Date().getUTCHours() < 18
        ) {
          return true;
        }
      } else {
        return true;
      }
    })
    .sort((a, b) => {
      const dateA = a.meta.webinars.webinarDate;
      const dateB = b.meta.webinars.webinarDate;

      if (!dateA || !dateB) {
        return 0;
      }

      return Date.parse(dateA) - Date.parse(dateB);
    });

  categorizedWebinars.upcoming = filteredUpcomingWebinars;

  return categorizedWebinars;
};

const formatAuthorPartial = (authorPartial) => {
  const { meta, slug } = authorPartial;

  return {
    slug,
    ...meta.authorData,
  };
};

const getBlogPostAuthors = async () => {
  const res = await fetchApiWithRetries(`
    query Post {
      partials(where: {partialTag: "blog-post-author-data"}, first: 200) {
        nodes {
          meta(locale: EN_US)
          slug
        }
      }
    }
  `);

  const authorPartials = res.partials.nodes.map((authorData) =>
    parseJSONS(authorData));

  return authorPartials.map(formatAuthorPartial);
};

const getBlogPostAuthorsMap = async () => {
  const authors = await getBlogPostAuthors();

  const authorsMap = authors.reduce((map, author) => {
    return {
      ...map,
      [author.slug]: author,
    };
  }, {});

  return authorsMap;
};

const populatePostAuthors = (post, authorsMap) => {
  if (!post.meta.blogPostData?._postAuthors?.length) {
    post.meta.blogPostData = post.meta.blogPostData || {};
    post.meta.blogPostData._postAuthors = ['the-rippling-team'];
  }

  const populatedPostAuthors = post.meta.blogPostData._postAuthors.map((authorSlug) => {
    if (!authorsMap[authorSlug]) {
      console.log('\n\n\nmissing author data:', authorSlug);
      throw new Error(`Missing blog post author data for "${authorSlug}". Please make sure this published and has the correct tags associated.`);
    }

    return authorsMap[authorSlug];
  });

  post.meta.blogPostData._postAuthorsDetails = populatedPostAuthors;

  return post;
};

const getBlogPosts = async ({
  includePageContent,
  isDevelopment,
  limit,
  locale,
}) => {
  const res = await fetchApiWithRetries(
    `query Post($locale: LocaleEnum, $status: [PostStatusEnum!]) {
      posts (first: ${limit}, where: {tagSlugIn: "${locale}", stati: $status}) {
        nodes {
          id
          wp
          meta(locale: $locale)
          ${includePageContent ? 'content(format: RAW)' : ''}
        }
      }
    }
  `,
    {
      variables: {
        locale: getWpLocaleEnum(LOCALES.EN_US),
        status: getWpStatus({ isDevelopment }),
      },
    }
  );

  const posts = res.posts.nodes.map((post) => parseJSONS(post));

  const authorsMap = await getBlogPostAuthorsMap();

  const populatedPosts = posts.map((post) =>
    populatePostAuthors(post, authorsMap));

  return populatedPosts;
};

const getEvents = async ({ isDevelopment, type }) => {
  const isCustomerEvents = null;

  // we always want to use 'events' for both
  type = isCustomerEvents ? 'events' : type;

  const res = await fetchApiWithRetries(
    `query Events($locale: LocaleEnum, $status: [PostStatusEnum!]) {
      events (first: 500, where: {stati: $status}) {
        nodes {
          wp
          meta(locale: $locale)
        }
      }
      secondary: constructs(type: "events", locale: $locale)
    }
  `,
    {
      variables: {
        locale: getWpLocaleEnum(LOCALES.EN_US),
        status: getWpStatus({ isDevelopment }),
      },
    }
  );

  const events = res[type].nodes.map((post) => parseJSONS(post));
  const categorizedEvents = sortEvents(events);
  const secondary = JSON.parse(res.secondary);
  const { data, partials } = secondary;

  return {
    categorizedEvents,
    data,
    partials,
  };
};

const getWebinars = async ({ isDevelopment, type }) => {
  const isCustomerWebinars = type === 'customer-webinars';

  // we always want to use 'webinars' for both
  type = isCustomerWebinars ? 'webinars' : type;

  const query = `
  query Webinars($locale: LocaleEnum, $status: [PostStatusEnum!]) {
    webinars(first: 500, where: { stati: $status }) {
      nodes {
        wp
        meta(locale: $locale)
      }
    }
    secondary: constructs(type: "webinars", locale: $locale)
  }
`;
  const res = await fetchApiWithRetries(query, {
    variables: {
      locale: getWpLocaleEnum(LOCALES.EN_US),
      status: getWpStatus({ isDevelopment }),
    },
  });

  const webinars = res[type].nodes.map(parseJSONS);
  const categorizedWebinars = filterAndCategorizeWebinars(
    webinars,
    isCustomerWebinars
  );

  const secondary = JSON.parse(res.secondary);
  const { data, partials } = secondary;

  return {
    categorizedWebinars,
    data,
    partials,
  };
};

const getGlossaryTerms = async ({ isDevelopment }) => {
  const query = `
  query GlossaryTerms($locale: LocaleEnum, $status: [PostStatusEnum!]) {
    glossaryTerms(first: 500, where: { stati: $status }) {
      nodes {
        wp
        meta(locale: $locale)
      }
    }
    secondary: constructs(type: "glossaryTerms", locale: $locale)
  }
`;
  const res = await fetchApiWithRetries(query, {
    variables: {
      locale: getWpLocaleEnum(LOCALES.EN_US),
      status: getWpStatus({ isDevelopment }),
    },
  });

  const glossaryTerms = res.glossaryTerms.nodes.map(parseJSONS);

  const secondary = JSON.parse(res.secondary);
  const { data, partials } = secondary;

  return {
    data,
    glossaryTerms,
    partials,
  };
};

export async function getPosts({
  locale, slug, type, ...options
}) {
  const isDevelopment = checkIsDevelopment();

  // we replaced this logic for posts with individual helpers
  if (type === 'posts') {
    return {
      posts: await getBlogPosts({
        includePageContent: options?.includePageContent,
        isDevelopment,
        limit: 1500,
        locale,
      }),
      slug: slug || null,
    };
  }

  // handled by its own logic now (as an optimization)
  switch (type) {
    case 'events':
    case 'customer-events': {
      const {
        categorizedEvents, data, partials,
      } = await getEvents({
        isDevelopment,
        type,
      });

      return {
        data,
        partials,
        posts: categorizedEvents,
        slug: slug || null,
      };
    }
    case 'webinars':
    case 'customer-webinars': {
      const {
        categorizedWebinars, data, partials,
      } = await getWebinars({
        isDevelopment,
        type,
      });

      return {
        data,
        partials,
        posts: categorizedWebinars,
        slug: slug || null,
      };
    }
    case 'glossaryTerms': {
      const {
        data, glossaryTerms, partials,
      } = await getGlossaryTerms({ isDevelopment });

      return {
        data,
        partials,
        posts: glossaryTerms,
        slug: slug || null,
      };
    }

  // No default
  }

  const nums = {
    customerSpotlights: 100,
    glossaryTerms: 500,
    recipes: 500,
  };

  const res = await fetchApiWithRetries(
    `
    query Post($locale: LocaleEnum, $status: [PostStatusEnum!]) {
      ${type}(first: ${nums[type] || 50}, where: {stati: $status, notIn: ${JSON.stringify(pagesToExclude)}}) {
        nodes {
          meta(locale: $locale)
          partials(locale: $locale)
          wp
          data(locale: $locale)
        }
      }
      secondary: constructs(type: "${type}", slug: "${slug}", locale: $locale)
    }
  `,
    {
      variables: {
        locale: getWpLocaleEnum(locale),
        status: getWpStatus({ isDevelopment }),
      },
    }
  );

  const posts = res[type].nodes.map((post) => {
    try {
      const parsePost = parseJSONS(post);
      parsePost.meta = localizeMeta(parsePost.meta, locale);

      return parsePost;
    } catch (error) {
      console.error('An error occurred:', error.message);

      return parseJSONS(post);
    }
  });

  // hiding until we get new customer index translated
  // if (['customerSpotlights'].includes(type)) {
  //   posts = posts.filter(post => {
  //     const postPublishedLocales = getPostPublishedLocales(post)
  //     return postPublishedLocales.includes(locale)
  //   })
  // }

  const secondary = JSON.parse(res.secondary);
  const { data, partials } = secondary;

  return {
    data,
    partials,
    posts,
    slug: slug || null,
  };
}

export const getMode = (construct, locale, isPagePreview) => {
  const isDevelopment = checkIsDevelopment();
  const status = construct.wp?.status;

  return {
    isDevelopment: isDevelopment || null,
    isMiniItSite: construct?.meta?.global?._isMiniItSite || false,
    isMiniSite: construct?.meta?.global?._isMiniSite || false,
    isPagePreview: isPagePreview || null,
    locale: locale || '',
    pageLocale: construct.pageLocale || '',
    pageLocaleDefaulted: construct.pageLocaleDefaulted || false,
    publishedLocales: getPostPublishedLocales(construct) || null,
    status: status || null,
  };
};

const requiresAuth = ({
  construct, isAuthenticated, mode,
}) => {
  return (
    construct?.meta?.global?.requiresAuth &&
    mode?.isDevelopment &&
    !isAuthenticated
  );
};

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const populateBlogPost = async ({ graphiteLinks, post }) => {
  // set the default if none selected
  if (!post?.meta?.blogPostData?._postAuthors?.length) {
    // this is a worst case scenario. bandaid solution. We should validate against this in the future.
    if (!post?.meta?.blogPostData) {
      post.meta.blogPostData = {};
    }
    post.meta.blogPostData._postAuthors = ['the-rippling-team'];
  }

  const getAuthorDetails = async () => {
    const authorsArray = [];
    await asyncForEach(
      post.meta.blogPostData._postAuthors,
      async (authorSlug) => {
        const authorDetail = await getAuthorDataPartial(authorSlug);
        authorDetail.slug = authorSlug;
        authorsArray.push(authorDetail);
      }
    );
    post.meta.blogPostData._postAuthorsDetails = authorsArray;
  };

  const getRelatedArticles = async () => {
    if (graphiteLinks.length > 0) {
      /**
       * Reason we fetch from DB is because we want to make sure the return data is the same as alternative path.
       * Otherwise, we need to refactor both code paths.
       */
      const allRelatedPosts = await Promise.allSettled(graphiteLinks.map((slug) =>
        getPost({
          locale: post.pageLocale,
          overrideContentLocale: post.overrideContentLocale,
          pageLocaleDefaulted: post.pageLocaleDefaulted,
          slug,
          type: BLOG_POST_WP_TYPE,
        })));

      const failedRelatedPosts = allRelatedPosts.filter(({ status }) => status === 'rejected');

      if (failedRelatedPosts.length > 0) {
        console.log(`Failed to fetch ${failedRelatedPosts.length} related posts for ${post.wp.slug}`);
      }

      post.data.sameCategoryPosts = allRelatedPosts
        .filter(({ status, value }) => status === 'fulfilled' && !!value)
        .map(({ value }) => value);
    } else {
      const relatedPostKeys = [
        '_relatedPost3',
        '_relatedPost2',
        '_relatedPost1',
      ];

      await asyncForEach(relatedPostKeys, async (key) => {
        if (
          post?.meta?.blogPostData[key] &&
          post?.meta?.blogPostData[key] !== 'Select a related post'
        ) {
          const relatedPost = await getPost({
            locale: post.pageLocale,
            overrideContentLocale: post.overrideContentLocale,
            pageLocaleDefaulted: post.pageLocaleDefaulted,
            slug: post.meta.blogPostData[key],
            type: BLOG_POST_WP_TYPE,
          });

          await getAuthorDetails(relatedPost);
          post.data.sameCategoryPosts.unshift(relatedPost);
        }
      });
    }
  };

  await getAuthorDetails();
  await getRelatedArticles();
};

const should404DraftPost = (post, isDevelopment) => {
  const postIsDraft = post.wp?.status === 'draft';

  // if its a draft and we're in production, 404...
  return postIsDraft && !isDevelopment;
};

// function convertECCSheetToJSON(csvJson) {
//   const [headers, ...rows] = csvJson;
//   const data = {};

//   for (let i = 0; i < rows.length; i++) {
//     const row = rows[i];
//     const [country, province, territory, percentage, maximum] = row;

//     if (!data[country]) {
//       data[country] = {};
//     }

//     let territoryData = data[country];

//     if (province) {
//       if (!data[country][province]) {
//         data[country][province] = {};
//       }
//       territoryData = data[country][province];
//     }

//     territoryData[territory] = {
//       percentage: percentage,
//       maximum: maximum !== 'null' ? maximum : null,
//     };
//   }

//   return data;
// }

const resolveDataBlock = async (blocks, locale) => {
  const result = [];

  for (const block of blocks) {
    if (block.innerBlocks) {
      block.innerBlocks = await resolveDataBlock(block.innerBlocks, locale);
    }

    if (
      ![
        'data',
        'employee-cost-calculator',
        'entity-registration-cost-calculator',
      ].includes(block.name)
    ) {
      result.push(block);
      continue;
    }

    switch (block.name) {
      case 'data': {
        const data = await fetchApiWithRetries(
          `
        query Data($locale: LocaleEnum) {
          ${block.attributes.query}
        }
        `,
          { variables: { locale: getWpLocaleEnum(locale) } }
        );

        block.attributes = {
          ...block.attributes,
          data,
        };

        break;
      }
      case 'employee-cost-calculator': {
        const res = await fetchApiWithRetries(
          `
        query Globals($locale: LocaleEnum) {
          data: partial(id: "employee-cost-calculator", idType: SLUG) {
            meta(locale: $locale)
          }
          form: partial(id: "employee-cost-calculator-form", idType: SLUG) {
            blocks(locale: $locale)
          }
        }
        `,
          { variables: { locale: getWpLocaleEnum(locale) } }
        );

        const data = JSON.parse(res.data.meta).data.data;
        const form = JSON.parse(res.form.blocks);

        // const res2 = await fetch(
        //   'https://www-staging.rippling.com/api/www-vercel-google-sheet-employee-cost-calculator',
        //   {
        //     method: 'GET',
        //     headers: {
        //       'Content-Type': 'application/json',
        //     },
        //     redirect: 'follow',
        //   }
        // )

        // const json = await res2.json()

        // const sheetVals = convertECCSheetToJSON(json.result.values)

        // Object.keys(sheetVals).forEach(country => {
        //   if (data[country]) {
        //     data[country].rates = sheetVals[country]
        //   }
        // })

        block.attributes = {
          ...block.attributes,
          data,
          form,
        };

        break;
      }
      case 'entity-registration-cost-calculator': {
        const res = await fetchApiWithRetries(
          `
        query EntityRegistrationCostCalculator($locale: LocaleEnum) {
          data: partial(id: "entity-registration-cost-calculator", idType: SLUG) {
            meta(locale: $locale)
          }
          form: partial(id: "entity-registration-cost-calculator-form", idType: SLUG) {
            blocks(locale: $locale)
          }
        }
        `,
          { variables: { locale: getWpLocaleEnum(locale) } }
        );

        const data = JSON.parse(res.data.meta).data;
        const form = JSON.parse(res.form.blocks);

        const fxRates = await readOrFetchFromPublicWithLog({
          defaultValue: {},
          filePath: `${FX_RATE_DIR}/usd-rates.json`,
          logPrefix: 'entity-registration-cost-calculator-helpers',
          noSuccessLog: true,
        });

        block.attributes = {
          ...block.attributes,
          data,
          form,
          fxRates,
        };

        break;
      }
    }

    result.push(block);
  }

  return result;
};

export const returnStaticProps = async ({
  graphiteLinks,
  locale,
  params,
  preview,
  previewData,
  query,
  slug,
  subPath,
  type,
  wpData,
  ...options
}) => {
  try {
    const isAuthenticated = previewData?.isAuthenticated || null;

    slug = params?.slug || slug;
    const args = {
      ...options,
      graphiteLinks,
      locale,
      previewData,
      query,
      slug,
      type,
      wpData,
    };
    const construct = query ? await getData(args) : await getWPData(args);

    // if no data found
    if (!construct) {
      return { notFound: true };
    }

    const mode = getMode(construct, locale, preview);

    // if page is in draft (staging only)
    if (should404DraftPost(construct, mode?.isDevelopment)) {
      return { notFound: true };
    }

    if (requiresAuth({
      construct,
      isAuthenticated,
      mode,
    })) {
      return { props: { requiresAuth: true } };
    }

    // blog posts need "_postAuthors" populated
    if (type === BLOG_POST_WP_TYPE) {
      await populateBlogPost({
        graphiteLinks,
        post: construct,
      });
    }

    // pull + populate reusables blocks
    construct.blocks = await resolveReusables(construct.blocks, false, locale);

    construct.blocks = await resolveDataBlock(construct.blocks, locale);

    const [
      alternativePages,
      globals,
      prodCssFiles,
    ] = await Promise.all([
      getAlternativePages({
        contentType: type,
        url: construct?.uri,
      }),
      getGlobals({
        data: {
          construct,
          mode,
        },
        locale,
        previewData,
        slug,
        subPath,
        type,
      }),
      getProductionCssFiles(),
    ]);

    construct.alternativePages = alternativePages;

    return {
      props: {
        construct,
        globals,
        mode,
        previewData: previewData || null,
        prodCssFiles,
      },
    };
  } catch (error) {
    console.error(
      `Failed to revalidate page. Params: ${JSON.stringify({
        locale,
        params,
        query,
        slug,
        type,
      })}`,
      error
    );

    throw error;
  }
};

// const getTopicsFromPosts = (posts) => {
//   const categories = {};

//   posts.forEach((post) => {
//     post.wp.categories.forEach(({ slug, name }) => {
//       categories[slug] = { slug, name };
//     });
//   });

//   return Object.values(categories);
// };

export async function getTopics() {
  const data = await fetchApiWithRetries(`
    query Categories {
      categories{
        nodes{
          name
          slug
        }        
      }
    }
  `);

  for (const [index] of data.categories.nodes.entries()) {
    if (data.categories.nodes[index].slug === 'uncategorized') {
      data.categories.nodes[index].slug = null;
      data.categories.nodes[index].name = 'All';
      data.categories.nodes[index].href = '/blog/topics';
      data.categories.nodes[index].weight = data.categories.nodes.length;
    } else {
      data.categories.nodes[
        index
      ].href = `/blog/topics?topics=${data.categories.nodes[index].slug}`;
      switch (data.categories.nodes[index].slug) {
        case 'for-hr': {
          data.categories.nodes[index].name = 'HR';
          data.categories.nodes[index].weight = 1;
          break;
        }
        case 'for-it': {
          data.categories.nodes[index].name = 'IT';
          data.categories.nodes[index].weight = 2;
          break;
        }
        case 'for-finance': {
          data.categories.nodes[index].name = 'Finance';
          data.categories.nodes[index].weight = 3;
          break;
        }
        case 'global': {
          data.categories.nodes[index].weight = 4;
          break;
        }
        case 'product': {
          data.categories.nodes[index].weight = 6;
          break;
        }
        case 'engineering': {
          data.categories.nodes[index].weight = 7;
          break;
        }
        case 'company': {
          data.categories.nodes[index].weight = 5;
          break;
        }
        case 'business-building': {
          data.categories.nodes[index].name = 'Business';
          data.categories.nodes[index].weight = 8;
          break;
        }
        case 'resources': {
          data.categories.nodes[index].weight = 9;
          break;
        }
        case 'founders': {
          data.categories.nodes[index].weight = 10;
          break;
        }
      }
    }
  }

  data.categories.nodes.sort((a, b) => {
    return a.weight - b.weight;
  });

  return data;
}

export { checkIsDevelopment, fetchAPI } from './_fetchAPI';
